.menu-button{
    width: 100% !important;
    text-align: left !important;
    outline: none !important;
    text-align: center !important;
}

.main-menu-container {
    position: -webkit-sticky; /* Safari */
    position: sticky;
    top: 0;
  }

  .menu-header{
      text-align: center !important;
  }