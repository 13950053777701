/* * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  
  body {
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica,
      Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  } */

/* .main-container {
    padding: 30px;
  } */

/* HEADING */

.app_heading {
  text-align: center;
}

.app_heading__title {
  font-weight: 600;
}

.app_heading__credits {
  margin: 10px 0px;
  color: #888888;
  font-size: 25px;
  transition: all 0.5s;
}

.app_heading__link {
  text-decoration: none;
}

.app_heading__credits .app_heading__link {
  color: inherit;
}

/* CARDS */

/* .app_cards {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  } */

.app_card {
  margin: 20px;
  padding: 20px;
  width: 95%;
  min-height: 90%;
  /* display: grid; */
  /* grid-template-rows: 20px 50px 1fr 50px; */
  border-radius: 10px;
  box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.25);
  transition: all 0.2s;
}

.app_card:hover {
  box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.4);
  transform: scale(1.01);
  filter: brightness(100%);
}

.app_card__link,
.app_card__exit,
.app_card__icon {
  position: relative;
  text-decoration: none;
  color: rgba(255, 255, 255, 0.9);
}

.app_card__link::after {
  position: absolute;
  top: 25px;
  left: 0;
  content: "";
  width: 0%;
  height: 3px;
  background-color: rgba(255, 255, 255, 0.6);
  transition: all 0.5s;
}

.app_card__link:hover::after {
  width: 100%;
}

.app_card__exit {
  grid-row: 1/2;
  justify-self: end;
}

.app_card__icon {
  grid-row: 2/3;
  font-size: 30px;
}

.app_card__title {
  font-weight: 400;
  font-size: 15px;
  margin: 10px;
  color: #ffffff;
  text-align: center;
}

.app_card__apply {
  grid-row: 4/5;
  align-self: center;
}

.app_card_image {
  width: 100%;
  height: 100px;
  filter: brightness(0) invert(1);
  display: block;
}

/* CARD BACKGROUNDS */

.app_card-1 {
  background: radial-gradient(#1fe4f5, #3fbafe);
}

.app_card-2 {
  background: radial-gradient(#fbc1cc, #fa99b2);
}

.app_card-3 {
  background: radial-gradient(#76b2fe, #b69efe);
}

.app_card-4 {
  background: radial-gradient(#60efbc, #58d5c9);
}

.app_card-5 {
  background: radial-gradient(#56ccde, #17a2b8);
}

/* RESPONSIVE */

@media (max-width: 1600px) {
  .app_cards {
    justify-content: center;
  }
}
