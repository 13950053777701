
#myAlert {
  visibility: hidden;
  position: fixed;
  color: white;
  border-radius: 10px;
  top: 0;
  right: 0;
  transform: translate(-15px, 15px);
  background: #17a2b8;
  border-radius: 10px;
  overflow: hidden;
  margin-bottom: 30px;
  box-shadow: 2px 3px 78px -14px rgba(0,0,0,1);
-webkit-box-shadow: 2px 3px 78px -14px rgba(0,0,0,1);
-moz-box-shadow: 2px 3px 78px -14px rgba(0,0,0,1);
}
.myAlert-text-icon {
  align-items: stretch;
  box-shadow: 0 1px 1px rgb(10 10 10 / 10%);
  display: flex;
  max-width: 250px;
}
.myAlert-message {
  align-items: center;
  display: flex;
  flex-grow: 1;
  font-weight: 700;
  padding: 15px 25px;
  font-size: 15px !important;
}
.close {
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  background: 0 0;
  border: none;
  color: currentColor;
  font-family: inherit;
  font-size: 1em;
  margin: 0;
  padding: 0;
  align-items: center;
  cursor: pointer;
  display: flex;
  justify-content: center;
  padding: 0.75rem 1rem;
}
#myAlert.show {
  visibility: visible;
}

#myAlert.show {
  animation: show 0.5s forwards;
}

@keyframes show {
  0% {
    transform: translate(-15px, 15px) scale(0);
  }
  50% {
    transform: translate(-15px, 15px) scale(1.2);
  }
  70% {
    transform: translate(-15px, 15px) scale(0.9);
  }
  100% {
    transform: translate(-15px, 15px) scale(1);
  }
}

#myAlertBar {
  height: 2px;
  background-color: #ddd;
  color: white;
}
#myAlertProgress {
  background-color: #17a2b8;
  width: 100%;
}
