.menu-button {
  width: 100% !important;
  text-align: left !important;
  outline: none !important;
  text-align: center !important;
}

.main-menu-container {
  position: -webkit-sticky; /* Safari */
  position: sticky;
  top: 0;
}

.menu-header {
  text-align: center !important;
}

.menu-btn-icon {
  font-size: 30px !important;
}
.badge {
  position: absolute;
  top: 0px;
  right: -5px;
  font-size: .8em;
  background-color: #076d7c !important;
}