body {
  background-color: #e9e9e9 !important;
  margin: 0 !important;
  padding: 0 !important;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 98% !important;
}

.group-button-select{
  margin: 0 !important;
}
.app-container {
  margin: 10px;
}
.btn {
  margin: 2px;
}
.row {
  margin: 0 !important;
}
a {
  text-decoration: none;
}
.dataTables_length {
  width: 100% !important;
}
.dataTables_length label {
  font-size: 12px !important;
  width: 100%;
}
.dataTables_length select {
  font-size: 12px !important;
  padding: 2px !important;
  outline: none !important;
  width: 30% !important;
}
.dataTables_filter {
  font-size: 12px !important;
}
.dataTables_filter input {
  font-size: 12px !important;
  padding: 2px !important;
  outline: none !important;
}
.sorting {
  font-size: 12px !important;
}
/* table{
  font-size: 12px !important;
} */
.loader {
  margin: 5px;
  margin: 0 auto;
}
.dropdown-menu {
  width: 100% !important;
}
.form-label {
  font-size: 11px !important;
  color: grey !important;
  margin-bottom: 0px !important;
  margin-top: 10px;
  text-transform: uppercase;
}
.form-control, .csv-input {
  font-size: 13px !important;
  font-weight: 600;
}
.csv-input {
  display: block;
  width: 100%;
  padding: 3px;
  font-size: 0.9375rem;
  line-height: 1.6;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 40, 100, 0.12);
  border-radius: 3px;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.card {
  margin-bottom: 10px !important;
}
.card-body {
  padding: 10px !important;
}
.col {
  padding-left: 5px !important;
  padding-right: 5px !important;
}
.call_status_btn {
  padding: 5px !important;
}
.paginate_button {
  padding: 0 !important;
  font-size: 12px;
}
.paginate_button:hover {
  border: 0px solid black !important;
  background-color: white !important;
  background: white !important ;
}
.dataTables_info {
  font-size: 12px;
}
.custom-select {
  padding: 0.375rem 0.75rem !important;
  line-height: 1.6 !important;
}
select.form-control:not([size]):not([multiple]) {
  height: auto !important;
}

tbody,
td,
tfoot,
th,
thead,
tr {
  border-style: none;
}

.page-link {
  padding: 5px !important;
}

/* .table td{
  width: 50%;
} */

.status-btn {
  width: 100%;
}

.nav-tabs {
  border-bottom: 0px solid gray !important;
}

.btn-checker {
  width: 100% !important;
}

.login-form-container {
  width: 100%;
  margin: 0 auto;
  margin-top: 20px;
  text-align: left;
  box-shadow: 2px 6px 21px -9px rgba(0, 0, 0, 0.6);
  -webkit-box-shadow: 2px 6px 21px -9px rgba(0, 0, 0, 0.6);
  -moz-box-shadow: 2px 6px 21px -9px rgba(0, 0, 0, 0.6);
}

.login-button {
  width: 100%;
  margin: 0 !important;
  margin-bottom: 3px !important;
  margin-top: 3px !important;
}

.login-header {
  text-align: center;
}

.background-image {
  margin-top: 5%;
  width: 85%;
}

.background-container {
  text-align: center;
}
.col-dashboard-container{
  padding: 0 !important;
}
.col-header-dashboard{
  padding: 5px !important;
  height: auto !important;
}
.menu-header{
  margin-bottom: 0 !important;
  line-height: normal;
  font-size: 13px;
  font-weight: bold;
}

.main-menu-container{
  z-index: 100 !important;
}
.header-menu-btn{
  font-size: large !important;
  margin: 5px !important;
  padding: 5px !important;
}
.search-btn{
  width: 100% !important;
}

.modal-summary {
  display: block; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 100000; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100vh; /* Full height */
  background-color: rgb(0,0,0); /* Fallback color */
  background-color: rgba(0,0,0,0.776); /* Black w/ opacity */
  overflow-x: auto; /* Enable scroll if needed */
}

/* Modal Content/Box */
.modal-summary-content-container {
  margin: 10px auto;
  width: 90% !important; /* Could be more or less, depending on screen size */
}

.modal-summary-content {
  height: 100% !important;
}

.summary-table{
  font-size: 12px!important;
}

.modal-achievement {
  display: block; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 100000; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100vh; /* Full height */
  background-color: rgb(0,0,0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.776); /* Black w/ opacity */
  overflow-x: auto; /* Enable scroll if needed */
}

/* Modal Content/Box */
.modal-achievement-content-container {
  margin: 50px auto;
  width: 50% !important; /* Could be more or less, depending on screen size */
}

.modal-online-orders {
  display: block; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 100000; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100vh; /* Full height */
  background-color: rgb(0,0,0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.776); /* Black w/ opacity */
  overflow-x: auto; /* Enable scroll if needed */
}

/* Modal Content/Box */
.modal-online-orders-content-container {
  margin: 50px auto;
  width: 90% !important; /* Could be more or less, depending on screen size */
}

.tabler_header_hr{
  margin: 0 auto;
  font-weight: bold;
  text-align: center;
  color: black;
}
.tabler_title_hr{
  margin: 0 auto;
  font-weight: bold;
  color: black;
}

.table_hr,
.th_hr {
  border: 2px solid rgba(0, 0, 0, 0.993);
  padding: 5px;
  border-collapse: collapse;
  font-weight: 700;
  color: black;
}
.th_hr{
  text-align: center;
}
.table_hr .td_hr {
  border: 2px solid rgba(0, 0, 0, 0.993);
  padding: 5px;
}
.td_hr{
  font-weight: 500;
  color: black;
}
.table_hr {
  width: 100%;
  margin: 0% auto;
}
.td_hr_footer{
  font-weight: 800;
  border: 2px solid rgba(0, 0, 0, 0.993);
  padding: 5px;
  border-collapse: collapse;
  color: black;
}


.th_sp_btm{
  border-bottom: 0px solid black;
}
.th_sp_top{
  border-top: 0px solid black;
}
.alert-css{
  margin-top: 5px !important;
  margin-bottom: 5px!important;
}
.grid-row-css{
  overflow-x: auto;
}
.btn-css{
  width: 100% !important;
}

.landing_page_image{
  -webkit-filter: drop-shadow( 3px 3px 2px rgba(80, 80, 80, 0.531));
  filter: drop-shadow( 3px 3px 2px rgba(80, 80, 80, 0.531));
}

.network-selection{
  display: inline-block !important;
  margin-right: 10px !important;
  text-transform: uppercase !important;
}