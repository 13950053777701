
table{
    font-size: 12px;
}
.serial-button-warning{
    font-size: 10px !important;
    padding: 3px !important;
}
.extraction-btn{
    text-align: right !important;
}